import request from '@/utils/request'

export function getPage (page, noitems) {
  return request({
    url: '/coverpage/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function search (data) {
  return request({
    url: '/coverpage/search',
    method: 'post',
    data
  })
}
export function filter (data) {
  return request({
    url: '/coverpage/filter',
    method: 'post',
    data
  })
}
export function storeCoverPage (data) {
  return request({
    url: '/coverpage',
    method: 'post',
    data
  })
}
export function getCoverPage (id) {
  return request({
    url: '/coverpage/' + id,
    method: 'get'
  })
}
export function updateCoverPage (id, data) {
  return request({
    url: '/coverpage/' + id,
    method: 'put',
    data
  })
}
export function destroyCoverPage (_id) {
  return request({
    url: '/coverpage/' + _id,
    method: 'delete'
  })
}
